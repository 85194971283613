@import 'pillpack-variables';

// Custom bootstrap variables go here
// Full list of variables here: https://github.com/twbs/bootstrap/blob/v4.4.1/scss/_variables.scss
$theme-colors: (
  'primary': $pp-primary-blue,
  'secondary': $pp-ocean,
  'danger': $pp-red,
  'success': $pp-green,
  'warning': $pp-orange,
  'light': $pp-border-blue,
  'dark': $pp-ocean,
  'white': $pp-white
);

// Flags
$enable-shadows: false;
$enable-responsive-font-sizes: true;

$standard-spacing-y: 1.25em;

// Overrides
$alert-bg-level: -11;
$alert-border-level: -10;
$alert-color-level: 10;

$body-bg: $pp-cream;
$body-color: $pp-ocean;
$breadcrumb-bg: transparent;

$display1-size: 5rem;
$display2-size: 4.5rem;
$display3-size: 3.5rem;
$display4-size: 2.5rem;

$font-family-base: $flama;

$small-font-size: 85%;

$input-color: $pp-ocean;
$input-border-color: $pp-border-blue;
$input-placeholder-color: $pp-blue-grey;
$input-font-weight: $f-weight-book;
$custom-select-bg-size: 12px 8px;
$custom-select-indicator: $pp-arrow-down-uri;

$breadcrumb-divider: quote(">");

$btn-border-radius: 30px;
$btn-border-radius-sm: 20px;
$btn-border-radius-lg: 50px;
$btn-disabled-opacity: .4;

$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 0.95rem;
$input-btn-font-size: 1.1875rem;
$input-btn-line-height: 1.2;

$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 0.75rem;
$input-btn-font-size-sm: 0.9rem;

$input-btn-padding-y-lg: 0.6rem;
$input-btn-padding-x-lg: 1.0rem;
$input-btn-font-size-lg: 1.45rem;

$btn-padding-x-sm: .85rem;
$btn-padding-x: 1.5rem;
$btn-padding-x-lg: 1.35em;

$btn-link-padding-x: .75rem;
$btn-link-padding-y: .375rem;

// reset other inputs back to normal
$card-border-radius: 15px;
$card-border-color: transparent;
$card-spacer-x: 1.25rem;
$card-spacer-y: $standard-spacing-y;

$container-padding-x: 0;

$dropdown-item-padding-y: .75em;
$dropdown-link-color: $body-color;

$grid-gutter-width: 20px;

$link-hover-color: $pp-primary-blue-hover;

$list-group-action-color: $txt-dark;
$list-group-border-color: $pp-border-blue;
$list-group-item-padding-y: 1.05em;

$popover-font-size: inherit;
$popover-border-color: $pp-border-blue;
$popover-box-shadow: $shadow-2;

$navbar-light-color: $pp-ocean;
$navbar-light-hover-color: $pp-primary-blue-hover;
$navbar-light-active-color: $pp-primary-blue;
$navbar-toggler-font-size: 1rem;

$nav-tabs-border-radius: 0;
$nav-tabs-border-color: $pp-border-blue;
$nav-tabs-link-hover-border-color:  transparent rgba($pp-md-grey, .5) $nav-tabs-border-color transparent;
$nav-tabs-link-active-bg: $pp-white;
$nav-tabs-link-active-color: $pp-ocean;
$nav-tabs-link-active-border-color: transparent $nav-tabs-border-color $nav-tabs-link-active-bg transparent;

$modal-backdrop-bg: $pp-ocean;
$modal-backdrop-opacity: 0.7;
$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;
$modal-content-border-radius: 20px;

$text-muted: $pp-blue-grey;
$tooltip-bg: $pp-ocean;

$enable-validation-icons: false;
$form-feedback-valid-color: $input-border-color;

$progress-height: 7px;
$progress-bg: $pp-snowball;

$custom-switch-width: 3rem;
$custom-switch-indicator-size: 1.5rem;
$custom-switch-indicator-border-radius: $custom-switch-indicator-size;

// Import bootstrap variables
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// if we change the zindex-modal-backdrop variable imported from bootstrap
// then we need to change the value in public/scripts/zendesk.js
:export {
  zIndexModal: $zindex-modal-backdrop
}
