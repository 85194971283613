@import 'settings';
@import 'functions/strip-units';
@import 'functions/em';
@import 'functions/rem';
// 1. Color
// 2. Spacing & Scale
// 3. Typography
// 4. Z-Index

// 1 Color
$pp-blue: #009beb;
$pp-primary-blue: #0074c0;
$pp-primary-blue-hover: #0062a2;
$pp-ocean: #143c5a;
$pp-snowball: #f0f8fc;

$pp-red: #d22b22;
$pp-orange: #ab5e05;
$pp-green: #018304;

$pp-dark-grey: #919191;
$pp-blue-grey: #5f788b;
$pp-md-grey: #c5c5c5;
$pp-white: #ffffff;
$pp-cream: #f7f5f3;
$pp-warning-bg: #fffaeb;

$pp-border-blue: #dae0e3;
$pp-cancel-gray: #f2f4f5;

// Computed / Derived
$pp-blue-highlight: $pp-snowball;
$step-progress-active: $pp-primary-blue;
$ship-step-progress-active: $pp-primary-blue;
$step-progress-inactive: $pp-md-grey;
$skeleton-loading-grey: $pp-blue-grey;
$prescription-tabs-bg: rgba($pp-md-grey, 0.1);

$txt-darkest: $pp-ocean;
$txt-dark: $pp-ocean;
$txt-med: darken($pp-dark-grey, 5%);

// Shadows
$clear-black: rgba(0, 0, 0, 0);
$shadow-1: 0 1px 2px fade-in($clear-black, 0.2);
$shadow-2: 0 2px 5px fade-in($clear-black, 0.2);

// 2 Spacing & Scale
$b-radius-sm: 3px;
$b-radius-lg: 5px;

// 3 Typography
$din: DIN, sans-serif;
$tiempos: 'Tiempos Headline', Georgia, serif;
$flama: Flama, sans-serif;

$f-size-xl: rem(55px);
$f-size-lg: rem(38px);
$f-size-md: rem(20px);
$f-size-rg: rem(18px);
$f-size-sm: rem(15px);
$f-size-xs: rem(12px);

$l-spacing-sm: rem(0px);
$l-spacing-md: rem(-0.25px);
$l-spacing-lg: rem(-0.3px);
$l-spacing-xl: rem(-1px);

$f-weight-book: 300;
$f-weight-regular: 400;
$f-weight-medium: 500;
$f-weight-semibold: 600;
$f-weight-bold: 700;

// 4 Z-Index Scale
$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;
$z-index-8: 800;
$z-index-9: 900;
$z-index-10: 1000;

// 5 Media Queries
$mq-sm: 450px;
$mq-md: 768px;

@mixin not-mobile {
  @media screen and (min-width: $mq-sm) and (min-height: 350px) {
    @content;
  }
}

$pp-arrow-down-uri: url("data:image/svg+xml,%3Csvg width='9' height='6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 1L4.08 5 0 1' stroke='%230074C0' fill='none' fill-rule='evenodd' stroke-linecap='square'/%3E%3C/svg%3E");
$pp-search-icon-uri: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 0C16.644 0 20 3.356 20 7.5S16.644 15 12.5 15a7.374 7.374 0 01-4.005-1.157l-5.648 5.67a1.684 1.684 0 01-1.18.487c-.44 0-.857-.162-1.18-.486a1.677 1.677 0 010-2.361l5.67-5.648A7.374 7.374 0 015 7.5C5 3.356 8.356 0 12.5 0zm0 12.5c2.755 0 5-2.245 5-5s-2.245-5-5-5-5 2.245-5 5 2.245 5 5 5z' fill='%230074C0' fill-rule='nonzero'/%3E%3C/svg%3E");
$pp-location-icon-url: url("data:image/svg+xml,%3Csvg width='20px' height='23px' viewBox='0 0 20 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Add-new-prescription' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='5.2-add-pharmacy' transform='translate(-255.000000, -281.000000)'%3E%3Crect fill='%23FFFFFF' x='0' y='0' width='375' height='812'%3E%3C/rect%3E%3Cg id='location-icon' transform='translate(256.000000, 282.000000)' stroke='%2397A7B4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8.5,21.0263158 C8.5,21.0263158 0.447368421,14.3157895 0.447368421,8.94736842 C0.447368421,4.25357895 3.80531579,0.447368421 8.5,0.447368421 C13.1937895,0.447368421 16.5526316,4.25357895 16.5526316,8.94736842 C16.5526316,14.3157895 8.5,21.0263158 8.5,21.0263158 Z' id='Stroke-78' stroke-width='2'%3E%3C/path%3E%3Cpath d='M12.0789474,8.5 C12.0789474,10.4762947 10.4762947,12.0789474 8.5,12.0789474 C6.52298947,12.0789474 4.92105263,10.4762947 4.92105263,8.5 C4.92105263,6.52370526 6.52298947,4.92105263 8.5,4.92105263 C10.4762947,4.92105263 12.0789474,6.52370526 12.0789474,8.5 L12.0789474,8.5 Z' id='Stroke-80' stroke-width='1.5'%3E%3C/path%3E%3C/g%3E%3Cpath d='M246.5,267.5 L246.5,317.5 L342,317.5 C343.380712,317.5 344.5,316.380712 344.5,315 L344.5,270 C344.5,268.619288 343.380712,267.5 342,267.5 L246.5,267.5 Z' id='Rectangle-Copy-19' stroke='%23DBDBDB'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
