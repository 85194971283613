@import "~@/styles/variables";






































.laser-focus {
  position: relative;
  padding: 45px;
  min-height: 100vh;
  background: $pp-white;

  .header {
    position: fixed;
    height: 60px;
    top: 26px;
    left: 45px;
    right: 45px;

    button {
      position: absolute;
      padding: 10px;

      &.close {
        right: -10px;
      }

      &.back {
        left: -10px;
        padding: 15px 10px;
      }
    }
  }

  .content {
    width: 392px;
    max-width: 100%;
    margin: 6px auto 0;
  }
}

@media (max-width: map-get($grid-breakpoints, 'sm')) {
  .laser-focus {
    padding: 30px;

    .header {
      height: 60px;
      top: 10px;
      left: 28px;
      right: 28px;
    }

    .content {
      margin: 40px auto;
    }
  }
}
