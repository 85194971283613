@import "~@/styles/variables";















































































.rx-modal-body {
  font-size: 1.3rem;
}
.learn-more {
  text-decoration: none;
  font-weight: $f-weight-bold;
  display: flex;
  .text {
    margin: 0 0.5em;
  }
  > svg {
    height: 22px;
  }
}
.bullet-list {
  margin-top: 0;
  li::before {
    color: $txt-dark;
  }
}
